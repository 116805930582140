import { useState } from "react";
const useModal = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  function toggleModal() {
    setIsVisible(!isVisible);
  }
  return {
    isVisible,
    toggleModal,
  };
};
export default useModal;
