// Utility Functions 
import { ScaleConfiguration } from './WTNScaleCanvas.types'

export const createScaleConfig: ((canvas: HTMLCanvasElement, canvasWidth: number, WTN: number, labels: boolean, version: number, confidence: number, sourceIsPsq: boolean) => ScaleConfiguration) = (canvas, canvasWidth, WTN, labels, version, confidence, sourceIsPsq) => {

  canvas.width = canvasWidth;
  canvas.height = canvasWidth;
  const scaleUnit = canvas.width;
  let score: number = WTN;

  if (isNaN(score)) {
    score = 40;
  }
  if (score > 40) {
    score = 40;
  }

  const scaleRadius = scaleUnit / 2 - scaleUnit / 3.7,
    scaleLineWidth = scaleUnit / 13,
    needleLength = scaleRadius - scaleLineWidth / 2,
    needleWidth = scaleUnit / 75

  let scoreFontsize: number, scoreXpos: number, scoreYpos: number;

  if (version === 2) {
    scoreFontsize = scaleUnit / 8;
    scoreXpos = scaleUnit / 2;
    scoreYpos = scaleUnit / 2 - scaleUnit / 1500;
  }
  else {
    scoreFontsize = scaleUnit / 13;
    scoreXpos = scaleUnit / 2;
    scoreYpos = scaleUnit / 2 + scaleUnit / 4.1;
  }

  return {
    baseUnit: scaleUnit,
    radius: scaleRadius,
    startingAngle: (Math.PI / 2) + (Math.PI / 10),
    lineWidth: scaleLineWidth,
    needleLength: needleLength,
    needleWidth: needleWidth,
    primaryColor: 'rgba(0,58,112,1)',
    primaryColorAlt: '#16234c',
    secondaryColor: 'rgba(224,62,80,1)',
    tertiaryColor: '#8F99B7',
    backgroundColor: '#ffffff',
    greyColor: '#f3f2f3',
    textFontFamily: 'Poppins',
    staticDrawingFinishAngle: (Math.PI / 2) - (Math.PI / 10),
    gameZoneFontSize: scaleUnit / 35,
    scoreXpos,
    scoreYpos,
    scoreFontsize: scaleUnit / 8,
    scoreSignsFontsize: scaleUnit / 35,
    noMatchesFontsize: scaleUnit / 30,
    scoreLabels: labels,
    proTextFontSize: scaleUnit / 17,
    version,
    score,
    confidence,
    sourceIsPsq
  }
}


export const linearMapping = (progress: number, f0: number, f1: number): number => {
  return f0 + ((f1 - f0) * progress);
}


export const mapRange = (value: number, low1: number, high1: number, low2: number, high2: number): number => {
  return low2 + (high2 - low2) * (value - low1) / (high1 - low1);
}

export const polarToCartesian = (r: number, angle: number) => {

  return { x: r * Math.cos(angle), y: r * Math.sin(angle) }

}


export const roundDownWTN = (wtn: number): string => {

  return (Math.round((wtn + Number.EPSILON) * 100) / 100).toFixed(2).toString().slice(0, -1)

}

export const countDecimals = (num: number) => {

  if (Math.floor(num.valueOf()) === num.valueOf()) return 0;

  var str = num.toString();
  if (str.indexOf(".") !== -1 && str.indexOf("-") !== -1) {
    return str.split("-")[1] || 0;
  } else if (str.indexOf(".") !== -1) {
    return str.split(".")[1].length || 0;
  }
  return str.split("-")[1] || 0;
}