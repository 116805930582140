import React, { useEffect, useRef } from "react";
import { easeInOutExpo, easeOutElastic } from "./EasingFunctions";
import {
  drawGameZone,
  drawNoMatchesScale,
  drawProScale,
  drawScale,
} from "./DrawingFunctions";
import {
  countDecimals,
  createScaleConfig,
  linearMapping,
  mapRange,
} from "./UtilityFunctions";
import { WTNScaleCanvasProps } from "./WTNScaleCanvas.types";

declare global {
  interface Window {
    requestAnimFrame: Function;
  }
}

const WTNScale: React.FC<WTNScaleCanvasProps> = ({
  WTN,
  noWTNSingles,
  noWTNDoubles,
  gameZoneLower,
  gameZoneUpper,
  canvasWidth,
  animDuration,
  labels,
  version,
  proPlayer,
  confidence,
  sourceIsPsq,
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (!canvasRef.current) {
      return;
    }

    const canvas: HTMLCanvasElement = canvasRef.current;
    const ctx = canvas.getContext("2d", { alpha: false });

    if (ctx) {
      if (proPlayer) {
        const scaleSettings = createScaleConfig(
          canvas,
          canvasWidth,
          1,
          labels,
          version,
          confidence,
          sourceIsPsq
        );
        drawProScale(ctx, scaleSettings);
      } else if (!proPlayer && WTN) {
        // create scale setting object
        const scaleSettings = createScaleConfig(
          canvas,
          canvasWidth,
          WTN,
          labels,
          version,
          confidence,
          sourceIsPsq
        );
        const start = performance.now();
        const step = (time: number) => {
          // timeFraction goes from 0 to 1
          let timeFraction = (time - start) / animDuration;
          if (timeFraction > 1) timeFraction = 1;

          const progress1 = easeInOutExpo(timeFraction + 0.2);

          const currentAngle = linearMapping(
            progress1,
            scaleSettings.startingAngle,
            mapRange(
              scaleSettings.score,
              40,
              1,
              scaleSettings.startingAngle,
              2 * Math.PI + scaleSettings.staticDrawingFinishAngle
            )
          );
          if (WTN > 10 && gameZoneLower && gameZoneUpper) {
            if (timeFraction < 0.8) {
              drawScale(ctx, currentAngle, scaleSettings);
            } else if (timeFraction >= 0.8 && timeFraction <= 1) {
              drawScale(ctx, currentAngle, scaleSettings);

              const GZ_timeFranction =
                (time - start) / (0.2 * animDuration) - 4;

              const GZ_lineWidth = linearMapping(
                  easeOutElastic(GZ_timeFranction),
                  0,
                  (2 / 2.5) * scaleSettings.lineWidth
                ),
                GZ_WTN_Upper = linearMapping(
                  easeInOutExpo(GZ_timeFranction),
                  0,
                  countDecimals(gameZoneUpper) === 1
                    ? gameZoneUpper + 0.01
                    : gameZoneUpper
                ), //Added correction of 0.01 when one decimal is received from the service, this will be truncated later.
                GZ_WTN_Lower = linearMapping(
                  easeInOutExpo(GZ_timeFranction),
                  0,
                  countDecimals(gameZoneLower) === 1
                    ? gameZoneLower + 0.01
                    : gameZoneLower
                );

              drawGameZone(
                ctx,
                scaleSettings.baseUnit / 2,
                scaleSettings.baseUnit / 2,
                scaleSettings.radius + (1 / 10) * scaleSettings.lineWidth,
                GZ_lineWidth,
                scaleSettings.primaryColor,
                currentAngle,
                scaleSettings.baseUnit,
                scaleSettings.gameZoneFontSize,
                Number.isInteger(gameZoneLower)
                  ? Math.round((GZ_WTN_Lower * 10) / 10)
                  : GZ_WTN_Lower, //When gameZone is integer we need to round to 1 decimal
                Number.isInteger(gameZoneUpper)
                  ? Math.round((GZ_WTN_Upper * 10) / 10)
                  : GZ_WTN_Upper,
                gameZoneLower,
                gameZoneUpper
              );
            }
          } else {
            drawScale(ctx, currentAngle, scaleSettings);
          }

          if (timeFraction < 1) {
            window.requestAnimationFrame(step);
          }
        };
        window.requestAnimationFrame(step);
      } else if (!WTN) {
        const scaleSettings = createScaleConfig(
          canvas,
          canvasWidth,
          40,
          labels,
          version,
          confidence,
          sourceIsPsq
        );
        if (noWTNSingles) {
          drawNoMatchesScale(ctx, scaleSettings, "noSingles");
        }
        if (noWTNDoubles) {
          drawNoMatchesScale(ctx, scaleSettings, "noDoubles");
        }
      }
    }
  }, [
    WTN,
    canvasWidth,
    animDuration,
    labels,
    version,
    gameZoneUpper,
    gameZoneLower,
    proPlayer,
    confidence,
    sourceIsPsq,
  ]);

  return (
    <>
      <canvas
        ref={canvasRef}
        style={{
          width: canvasWidth,
          height: canvasWidth,
          backgroundColor: "#ffffff",
        }}
      ></canvas>
    </>
  );
};

export default WTNScale;
