import React, { useState, useEffect } from "react";
import { WtnScaleData, WTNScaleDataProps } from "./WTNScaleData.types";
import WTNScale from "../WTNScale/WTNScale";
import WTNSpinner from "../WTNSpinner/WTNSpinner";
import { WorldTennisNumber } from "./WTNScaleData.types";
import { minConfidence, minWTN } from "./constants";
import { transformWTN } from "./dataTransforms";
const WTNScaleData: React.FC<WTNScaleDataProps> = ({
  mode = "",
  tennisID,
  endpointURL,
  width = 350,
  animDuration = 2000,
  mobileBreakPoint = 767.98,
  labelsDesktop = true,
  labelsMobile = false,
  border = false,
  infoIcon = true,
  infoIconSize = 20,
  infoModal = true,
  infoModalZIndex = 500,
}) => {
  const initState = {
    WTNSingles: undefined,
    WTNDoubles: undefined,
    gameZoneUpperSingles: minWTN,
    gameZoneLowerSingles: minWTN,
    gameZoneUpperDoubles: minWTN,
    gameZoneLowerDoubles: minWTN,
    rankedSingles: false,
    rankedDoubles: false,
    confidenceSingles: minConfidence,
    confidenceDoubles: minConfidence,
    sourceSingles: undefined,
    sourceDoubles: undefined,
  };

  const [data, setData] = useState<WtnScaleData>(initState);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const WTNLog = (errorMessage: string) => {
    const scaleGenericText = "WTN SCALE WIDGET:";
    const errorStyle = "background: white; color:red; padding:3px 10px";
    const successStyle = "background: green; color:white; padding:3px 10px";
    const genericStyle =
      "background: linear-gradient(90deg, rgba(224,62,80,1) 6%, rgba(0,58,112,1) 86%); padding:3px 10px";
    if (errorMessage === "no error") {
      console.log(
        `%c ${scaleGenericText} %c Successfully Rendered`,
        genericStyle,
        successStyle
      );
    } else {
      console.log(
        `%c ${scaleGenericText} %c Error -> ${errorMessage} `,
        genericStyle,
        errorStyle
      );
    }
  };

  useEffect(() => {
    if (!tennisID) {
      WTNLog("tennisID was not provided");
    } else {
      const fetchURL = endpointURL
        ? endpointURL
        : "https://prd-itf-kube.clubspark.io/tods-gw-api/graphql";
      const fetchWTNs = async () => {
        try {
          const response = await fetch(`${fetchURL}`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              accept: "application/json",
            },
            body: JSON.stringify({
              query: `
                               query worldTennisNumber($tennisID: ID!) {                            
                                     worldTennisNumber(tennisID:$tennisID) {
                                       confidence
                                       tennisNumber
                                       type
                                       gameZoneUpper
                                       gameZoneLower
                                       isRanked                                 
                                       source
                                   }
                                 }
                                `,
              variables: { tennisID: tennisID },
            }),
          });

          const result = await response.json();

          if (result.errors) {
            WTNLog(result.errors[0].message);
          } else {
            const WTNs: WorldTennisNumber[] = result.data.worldTennisNumber;
            if (WTNs && WTNs.length > 0) {
              setData(transformWTN(WTNs));
            } else {
              setData(initState);
            }
            setIsLoading(false);
            WTNLog("no error");
          }
        } catch (err) {
          WTNLog("WTNScale" + err);
        }
      };
      fetchWTNs();
    }
  }, [tennisID, endpointURL]);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            width: width,
            height: width,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <WTNSpinner width={150} />
        </div>
      ) : (
        <WTNScale
          mode={mode}
          wtnSingles={data.WTNSingles}
          wtnDoubles={data.WTNDoubles}
          gameZoneLower={data.gameZoneLowerSingles}
          gameZoneUpper={data.gameZoneUpperSingles}
          width={width}
          animDuration={animDuration}
          mobileBreakPoint={mobileBreakPoint}
          labelsDesktop={labelsDesktop}
          labelsMobile={labelsMobile}
          border={border}
          infoIcon={infoIcon}
          infoIconSize={infoIconSize}
          infoModal={infoModal}
          infoModalZIndex={infoModalZIndex}
          proPlayerSingles={data.rankedSingles}
          proPlayerDoubles={data.rankedDoubles}
          confidenceSingles={data.confidenceSingles}
          confidenceDoubles={data.confidenceDoubles}
          sourceIsPsqSingles={data.sourceSingles === "PSQ"}
          sourceIsPsqDoubles={data.sourceDoubles === "PSQ"}
        />
      )}
    </>
  );
};

export default WTNScaleData;
