import { minConfidence, minWTN } from "./constants";
import { WorldTennisNumber } from "./WTNScaleData.types";

export const transformWTN = (WTNs: WorldTennisNumber[]) => {
  const ratingDataSingles: WorldTennisNumber | undefined = WTNs.find(
    (x: { type: string }) => {
      return x.type === "SINGLE";
    }
  );
  const ratingDataDoubles: WorldTennisNumber | undefined = WTNs.find(
    (x: { type: string }) => {
      return x.type === "DOUBLE";
    }
  );
  return {
    WTNSingles: ratingDataSingles ? ratingDataSingles.tennisNumber : undefined,
    gameZoneUpperSingles: ratingDataSingles
      ? ratingDataSingles.gameZoneUpper
      : minWTN,
    gameZoneLowerSingles: ratingDataSingles
      ? ratingDataSingles.gameZoneLower
      : minWTN,
    confidenceSingles: ratingDataSingles
      ? ratingDataSingles.confidence
      : minConfidence,
    rankedSingles: ratingDataSingles ? ratingDataSingles.isRanked : false,
    sourceSingles: ratingDataSingles ? ratingDataSingles.source : undefined,
    WTNDoubles: ratingDataDoubles ? ratingDataDoubles.tennisNumber : undefined,
    gameZoneUpperDoubles: ratingDataDoubles
      ? ratingDataDoubles.gameZoneUpper
      : minWTN,
    gameZoneLowerDoubles: ratingDataDoubles
      ? ratingDataDoubles.gameZoneLower
      : minWTN,
    confidenceDoubles: ratingDataDoubles
      ? ratingDataDoubles.confidence
      : minConfidence,
    rankedDoubles: ratingDataDoubles ? ratingDataDoubles.isRanked : false,
    sourceDoubles: ratingDataDoubles ? ratingDataDoubles.source : undefined,
  };
};
