import React, { SyntheticEvent, useCallback, useState } from "react";
import styles from "./WTNSelect.module.scss";

const arrowDown = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m17 9.6-4.5 4.8L8 9.6"
      stroke="#16234c"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const arrowUp = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m7.5 15 4.5-4.8 4.5 4.8"
      stroke="#16234c"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

interface Props {
  selected: string;
  values: string[];
  setSelected: (val: string) => void;
}

const WTNSelect = ({ selected, values, setSelected }: Props) => {
  const [menuIsOpen, setMenhuIsOpen] = useState(false);
  const handleChange = useCallback((e) => {
    setSelected(e.target.value);
    setMenhuIsOpen(false);
  }, []);

  return (
    <div className={styles.container}>
      <button
        onClick={() => setMenhuIsOpen(!menuIsOpen)}
        className={`${styles.toggle} ${menuIsOpen ? styles.withMenuOpen : ""}`}
      >
        {selected}
        {menuIsOpen ? arrowUp : arrowDown}
      </button>
      <div
        className={`${styles.menu} ${menuIsOpen ? styles.menu__isOpen : ""}`}
      >
        {values.map((value, index) => (
          <label
            key={index}
            className={`${styles.option} ${
              selected === value ? styles.optionSelected : ""
            }`}
          >
            <input
              type="radio"
              name={`wtn-${value}`}
              checked={selected === value}
              value={value}
              onChange={handleChange}
            />
            {value}
          </label>
        ))}
      </div>
    </div>
  );
};

export default WTNSelect;
