import * as React from "react";
import styles from "./Modal.module.scss";
import ReactDOM from "react-dom";

const blueTick = (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.4 1.881a1.546 1.546 0 0 1-.399-.71 1.548 1.548 0 0 0-3.002 0 1.547 1.547 0 0 1-2.613.701 1.547 1.547 0 0 0-2.6 1.501 1.547 1.547 0 0 1-1.913 1.913 1.547 1.547 0 0 0-1.501 2.6 1.547 1.547 0 0 1-.7 2.613 1.547 1.547 0 0 0 0 3.002 1.548 1.548 0 0 1 .7 2.613 1.547 1.547 0 0 0 1.5 2.6 1.546 1.546 0 0 1 1.914 1.913 1.547 1.547 0 0 0 2.6 1.5 1.547 1.547 0 0 1 2.613.701 1.547 1.547 0 0 0 3.002 0 1.546 1.546 0 0 1 2.613-.7 1.547 1.547 0 0 0 2.6-1.501 1.547 1.547 0 0 1 1.913-1.913 1.548 1.548 0 0 0 1.501-2.6 1.548 1.548 0 0 1 .7-2.613 1.548 1.548 0 0 0 0-3.002 1.547 1.547 0 0 1-.7-2.613 1.546 1.546 0 0 0-1.5-2.6 1.547 1.547 0 0 1-1.914-1.913 1.547 1.547 0 0 0-2.6-1.5 1.547 1.547 0 0 1-2.215.008zm2.876 7.674a.8.8 0 1 0-1.152-1.11L10.9 13.86l-2.036-2.014a.8.8 0 1 0-1.126 1.138l2.54 2.512a.9.9 0 0 0 1.28-.015l5.719-5.927z"
      fill="#003A70"
    />
  </svg>
);

const greyTick = (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.4 1.881a1.546 1.546 0 0 1-.399-.71 1.548 1.548 0 0 0-3.002 0 1.547 1.547 0 0 1-2.613.701 1.547 1.547 0 0 0-2.6 1.501 1.547 1.547 0 0 1-1.913 1.913 1.547 1.547 0 0 0-1.501 2.6 1.547 1.547 0 0 1-.7 2.613 1.547 1.547 0 0 0 0 3.002 1.548 1.548 0 0 1 .7 2.613 1.547 1.547 0 0 0 1.5 2.6 1.546 1.546 0 0 1 1.914 1.913 1.547 1.547 0 0 0 2.6 1.5 1.547 1.547 0 0 1 2.613.701 1.547 1.547 0 0 0 3.002 0 1.546 1.546 0 0 1 2.613-.7 1.547 1.547 0 0 0 2.6-1.501 1.547 1.547 0 0 1 1.913-1.913 1.548 1.548 0 0 0 1.501-2.6 1.548 1.548 0 0 1 .7-2.613 1.548 1.548 0 0 0 0-3.002 1.547 1.547 0 0 1-.7-2.613 1.546 1.546 0 0 0-1.5-2.6 1.547 1.547 0 0 1-1.914-1.913 1.547 1.547 0 0 0-2.6-1.5 1.547 1.547 0 0 1-2.215.008zm2.876 7.674a.8.8 0 1 0-1.152-1.11L10.9 13.86l-2.036-2.014a.8.8 0 1 0-1.126 1.138l2.54 2.512a.9.9 0 0 0 1.28-.015l5.719-5.927z"
      fill="#CBCDD1"
    />
  </svg>
);

interface ModalProps {
  isVisible: Boolean;
  hideModal: () => void;
  zIndex: number;
}

const Modal: React.FC<ModalProps> = ({ isVisible, hideModal, zIndex }) => {
  return isVisible
    ? ReactDOM.createPortal(
        <>
          <div
            className={`wtnScale-modalOverlay ${styles.modalOverlay}`}
            style={{ zIndex: zIndex }}
            onClick={hideModal}
          />
          <div
            className={`wtnScale-modalWrapper ${styles.modalWrapper}`}
            aria-modal={true}
            aria-hidden={true}
            tabIndex={-1}
            role="dialog"
            style={{ zIndex: zIndex }}
          >
            <div className={`wtnScale-modal ${styles.modal}`}>
              <div className={`wtnScale-modal__body ${styles.modalBody}`}>
                <section>
                  <h2 className={styles.sectionHeader}>Number Verification</h2>
                  <p>
                    Your number has a verification tick, this relates to degree
                    of confidence
                  </p>
                  <div
                    className={`wtnScale-modal__featureContainer ${styles.featureContainer}`}
                  >
                    <div className={styles.feature}>
                      <div
                        className={`wtnScale-modal__featureCard ${styles.featureCard}`}
                      >
                        <div
                          className={`wtnScale-modal__featureCard__header ${styles.featureCard__header}`}
                        >
                          <span>Confident</span>
                        </div>
                        <div
                          className={`wtnScale-modal__featureCard__body ${styles.featureCard__body}`}
                        >
                          <div className={styles.featureCard__numberContainer}>
                            <span
                              className={`wtnScale-modal__featureCard__number ${styles.featureCard__number}`}
                            >
                              24<span>.5</span>
                            </span>
                            {blueTick}
                          </div>
                          <span className={styles.featureCard__numberLabel}>
                            Blue tick
                          </span>
                        </div>
                      </div>
                      <ul className={`wtnScale-modal__featureCard__list`}>
                        <li>
                          We have lots of results for you so have a better
                          understanding of your ability
                        </li>
                        <li>You can be confident your Number is accurate</li>
                      </ul>
                    </div>
                    <div className={styles.feature}>
                      <div
                        className={`wtnScale-modal__featureCard ${styles.featureCard}`}
                      >
                        <div
                          className={`wtnScale-modal__featureCard__header ${styles.featureCard__header}`}
                        >
                          <span>More data required</span>
                        </div>
                        <div
                          className={`wtnScale-modal__featureCard__body ${styles.featureCard__body}`}
                        >
                          <div className={styles.featureCard__numberContainer}>
                            <span
                              className={`wtnScale-modal__featureCard__number ${styles.featureCard__number}`}
                            >
                              24<span>.5</span>
                            </span>
                            {greyTick}
                          </div>
                          <span className={styles.featureCard__numberLabel}>
                            Grey tick
                          </span>
                        </div>
                      </div>
                      <ul className={`wtnScale-modal__featureCard__list`}>
                        <li>
                          We need more recent results for you in order to better
                          understand your ability
                        </li>
                        <li>
                          Your number may not be as accurate as it could be
                        </li>
                      </ul>
                    </div>
                  </div>
                </section>
                <hr />
                <section>
                  <h2 className={styles.sectionHeader}>
                    Singles and Doubles Number
                  </h2>
                  <p>
                    Don’t forget to use the toggle to swap between your Singles
                    and Doubles number
                  </p>
                </section>
              </div>
              <div className={`wtnScale-modal__footer ${styles.modalFooter}`}>
                <button
                  type="button"
                  className={styles.button}
                  onClick={hideModal}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </>,
        window.document.body
      )
    : null;
};
export default Modal;
