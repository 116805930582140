import React from "react"
import styles from './WTNSpinner.module.scss'
import { WTNSpinnerProps } from "./WTNSpinner.types";


const WTNSpinner: React.FC<WTNSpinnerProps> = ({ width }) => {
  return (
    <div className={styles.wtnLoaderWrapper} style={{ width: width }}>

      {/* spinner */}
      <svg
        className={styles.wtnLoaderSpinner}
        width={width}
        height={width}
        viewBox="0 0 66 66"
      >
        <circle
          className={styles.wtnLoaderPath}
          fill="transparent"
          strokeWidth={2}
          cx={33}
          cy={33}
          r={30}
          stroke="url(#prefix__a)"
        />


        <linearGradient id="prefix__a">
          <stop offset="35%" stopColor="rgba(0,58,112,1)" />
          <stop offset="55%" stopColor="rgba(0,58,112,1)" stopOpacity={0.8} />
          <stop offset="80%" stopColor="rgba(0,58,112,1)" stopOpacity={0.5} />
          <stop offset="100%" stopColor="rgba(0,58,112,1)" stopOpacity={0} />
        </linearGradient>


        <svg
          className={styles.wtnLoaderSpinnerDot}
          width={5}
          height={5}
          viewBox="0 0 66 66"
          x={37}
          y={1.5}
        >

          <circle
            className={styles.wtnLoaderPath}
            fill="rgba(224,62,80,1)"
            cx={33}
            cy={33}
            r={30}
          />
        </svg>
      </svg>

      {/* ITF icon */}
      <svg
        data-name="Group 17"
        width={48.956}
        height={55.575}
        viewBox="0 0 48.956 55.575"
        className={styles.wtnLoader}
      >
        <defs>
          <linearGradient
            id="prefix__c"
            x1={0.583}
            y1={1.026}
            x2={0.417}
            y2={-0.026}
            gradientUnits="objectBoundingBox"
          >
            <stop offset={0.061} stopColor="#29235c" stopOpacity={0.698} />
            <stop offset={0.167} stopColor="#29235c" stopOpacity={0.651} />
            <stop offset={0.273} stopColor="#29235c" stopOpacity={0.6} />
            <stop offset={0.432} stopColor="#484373" stopOpacity={0.514} />
            <stop offset={0.679} stopColor="#9d9ab4" stopOpacity={0.275} />
            <stop offset={0.899} stopColor="#fff" stopOpacity={0} />
          </linearGradient>
          <linearGradient
            id="prefix__e"
            x1={0.583}
            y1={1.026}
            x2={0.417}
            y2={-0.026}
            gradientUnits="objectBoundingBox"
          >
            <stop offset={0.101} stopColor="#fff" stopOpacity={0} />
            <stop offset={0.492} stopColor="#babab9" stopOpacity={0.212} />
            <stop offset={0.939} stopColor="#1d1d1b" stopOpacity={0.698} />
          </linearGradient>
          <linearGradient
            id="prefix__g"
            x1={0.596}
            y1={1.021}
            x2={0.404}
            y2={-0.021}
            gradientUnits="objectBoundingBox"
          >
            <stop offset={0.101} stopColor="#fff" stopOpacity={0} />
            <stop offset={0.565} stopColor="#807c9e" stopOpacity={0.416} />
            <stop offset={0.939} stopColor="#29235c" stopOpacity={0.698} />
          </linearGradient>
          <linearGradient
            id="prefix__i"
            x1={0.596}
            y1={1.021}
            x2={0.404}
            y2={-0.021}
            gradientUnits="objectBoundingBox"
          >
            <stop offset={0.061} stopColor="#29235c" stopOpacity={0.4} />
            <stop offset={0.269} stopColor="#757296" stopOpacity={0.255} />
            <stop offset={0.632} stopColor="#dbdae3" stopOpacity={0.067} />
            <stop offset={0.899} stopColor="#fff" stopOpacity={0} />
          </linearGradient>
          <linearGradient
            id="prefix__a"
            x1={-170.951}
            y1={90.909}
            x2={-171.116}
            y2={89.857}
            gradientUnits="objectBoundingBox"
          >
            <stop offset={0.061} stopColor="#29235c" stopOpacity={0.698} />
            <stop offset={0.167} stopColor="#29235c" stopOpacity={0.651} />
            <stop offset={0.48} stopColor="#7b779a" stopOpacity={0.4} />
            <stop offset={0.899} stopColor="#fff" stopOpacity={0} />
          </linearGradient>
          <linearGradient
            id="prefix__l"
            x1={-267.662}
            y1={141.843}
            x2={-267.828}
            y2={140.792}
            gradientUnits="objectBoundingBox"
          >
            <stop offset={0.101} stopColor="#fff" stopOpacity={0} />
            <stop offset={0.52} stopColor="#7b779a" stopOpacity={0.4} />
            <stop offset={0.833} stopColor="#29235c" stopOpacity={0.651} />
            <stop offset={0.939} stopColor="#29235c" stopOpacity={0.698} />
          </linearGradient>
          <linearGradient
            id="prefix__n"
            x1={-229.356}
            y1={-107.706}
            x2={-229.522}
            y2={-108.758}
            xlinkHref="#prefix__a"
          />
          <clipPath id="prefix__b">
            <path
              data-name="Path 27"
              d="M39.287 36.528a15.7 15.7 0 00.86 1.874l.931 2.03c1.609 4.124 3.187 10.754 4.495 17.288.735-.142 1.529-.27 2.388-.38 1.359-.174 2.7-.345 4.037-.491-.029-1.7-.076-3.449-.145-5.215-.139-3.528-.6-6.946-1.094-10.576-1.429-10.542-4.7-12.913-6.553-13.367a5.205 5.205 0 00-3.534.274 4.568 4.568 0 00-2.559 6.008z"
              transform="translate(-37.729 -27.501)"
              fill="none"
            />
          </clipPath>
          <clipPath id="prefix__d">
            <path
              data-name="Path 28"
              d="M60.241 109.817c0-.528-.016-1.087-.021-1.589-.006-2.4.093-6.282.12-10.932-1.745.47-3.314.955-4.6 1.446l-.325.146c.931 5.39 1.555 9.77 1.7 11.2l.006.045v.01c.031.324.068.608.109.863l.158 1.249a1.438 1.438 0 001.569 1.246 1.376 1.376 0 001.361-1.421l-.07-2.265z"
              transform="translate(-55.415 -97.296)"
              fill="none"
            />
          </clipPath>
          <clipPath id="prefix__f">
            <path
              data-name="Path 32"
              d="M96.409 65.817a262.372 262.372 0 00-2.894-9.4l-.05-.008h-.015a38.626 38.626 0 00-5.635.007c1.131 8.11 2.167 17.724 1.858 23.41l-.251 2.219a15.757 15.757 0 00-.232 2.049l-.316 2.792a4.566 4.566 0 004.307 4.907 5.2 5.2 0 003.442-.843c1.614-1.008 3.985-4.283 2.049-14.741-.666-3.609-1.293-6.997-2.263-10.392z"
              transform="translate(-87.815 -56.305)"
              fill="none"
            />
          </clipPath>
          <clipPath id="prefix__h">
            <path
              data-name="Path 33"
              d="M82.915 25.826c.04.255.094.536.166.854v.01l.008.044c.23 1.027.706 3.632 1.258 7.074a97.28 97.28 0 012.021-.355c.767-.125 1.488-.23 2.169-.317-.789-2.288-1.457-4.2-1.913-5.6-.152-.478-.314-1.013-.477-1.515l-.64-2.174a1.376 1.376 0 00-1.736-.924 1.438 1.438 0 00-1.1 1.673z"
              transform="translate(-82.639 -22.864)"
              fill="none"
            />
          </clipPath>
          <clipPath id="prefix__j">
            <path
              data-name="Path 34"
              d="M64.618 82.2a154.732 154.732 0 00-8.716.012c-3 .1-6.017.483-9.108.878-8.978 1.15-10.947 4.2-11.345 5.77a4.426 4.426 0 00.212 3.008 3.886 3.886 0 005.1 2.214l2.18-.982a13.37 13.37 0 001.6-.72l1.733-.78c4.354-1.662 11.973-3.26 18.656-4.439a127.01 127.01 0 00-.312-4.961z"
              transform="translate(-35.279 -82.149)"
              fill="none"
            />
          </clipPath>
          <clipPath id="prefix__k">
            <path
              data-name="Path 35"
              d="M112.77 82.85l-1.928.046c-.449 0-.924.007-1.352.007-.873 0-1.977-.042-3.259-.095.235 1.085.454 2.178.665 3.289 1.986-.287 3.472-.474 4.159-.537h.046c.276-.024.518-.054.735-.088l1.064-.126a1.224 1.224 0 001.07-1.327 1.171 1.171 0 00-1.2-1.169z"
              transform="translate(-106.231 -82.81)"
              fill="none"
            />
          </clipPath>
          <clipPath id="prefix__m">
            <path
              data-name="Path 36"
              d="M90.02 44.6v-.022c-.157-.315.258-4.518-13.459-2.274a88.015 88.015 0 00-9.261 2.003c-2.047.568-3.57 1.011-5.489 1.6.207 1.416.43 3.017.7 5.022 7.3-1 14.56-1.818 18.683-1.429h.015c.302.049 9.505 1.584 8.811-4.9z"
              transform="translate(-61.814 -41.643)"
              fill="none"
            />
          </clipPath>
        </defs>
        <path
          data-name="Path 24"
          d="M19.181 51.886c0-.528-.016-1.087-.02-1.589-.011-4.4.329-13.741-.062-23.7-.139-3.528-.6-6.946-1.094-10.576-1.429-10.542-4.7-12.913-6.553-13.367a5.205 5.205 0 00-3.534.274 4.568 4.568 0 00-2.559 6.008l1.173 2.555a15.66 15.66 0 00.86 1.874l.931 2.03c3.768 9.657 7.366 33.064 7.729 36.767l.006.045v.01c.031.324.068.608.109.863l.158 1.249a1.438 1.438 0 001.569 1.246 1.376 1.376 0 001.361-1.421z"
          fill="#e53e51"
        />
        <path
          data-name="Path 25"
          d="M9.997 20.563a71.58 71.58 0 00-.975-3.216c-1.923.635-3.538 1.171-4.731 1.545-.585.18-1.267.375-1.836.566l-.062.017-.033.01h-.008c-.243.067-.455.134-.644.2l-.932.288a1.116 1.116 0 00-.74 1.366 1.067 1.067 0 001.267.848l1.48-.308c.2-.033.41-.075.646-.127l.412-.079v-.009c1.079-.225 3.304-.631 6.156-1.101z"
          fill="#2a3a6f"
        />
        <path
          data-name="Path 26"
          d="M45.794 31.798c-.45 0-.925.007-1.352.008-3.74-.018-11.653-.656-20.131-.385-3 .1-6.017.483-9.108.878-8.978 1.15-10.947 4.2-11.345 5.771a4.425 4.425 0 00.212 3.008 3.886 3.886 0 005.1 2.214l2.181-.982a13.371 13.371 0 001.6-.72l1.733-.78c8.24-3.146 28.176-6.062 31.328-6.348h.047c.276-.024.518-.054.735-.087l1.064-.126a1.223 1.223 0 001.07-1.327 1.17 1.17 0 00-1.2-1.167z"
          fill="#2a3a6f"
        />
        <g data-name="Group 4">
          <g
            data-name="Group 3"
            clipPath="url(#prefix__b)"
            transform="translate(4.985 2.452)"
            opacity={0.66}
          >
            <path
              data-name="Rectangle 3"
              transform="translate(-1.56 -1.585)"
              opacity={0.64}
              fill="url(#prefix__c)"
              d="M0 0h20.429v32.62H0z"
            />
          </g>
        </g>
        <g data-name="Group 6">
          <g
            data-name="Group 5"
            clipPath="url(#prefix__d)"
            transform="translate(14.339 39.366)"
            opacity={0.66}
          >
            <path
              data-name="Rectangle 4"
              transform="translate(-4.576 -.152)"
              fill="url(#prefix__e)"
              d="M0 0h10.939v17.467H0z"
            />
          </g>
        </g>
        <path
          data-name="Path 29"
          d="M32.25 3.151c.163.5.324 1.038.477 1.515 1.362 4.178 4.6 12.948 7.343 22.532.971 3.394 1.6 6.785 2.264 10.386 1.934 10.459-.436 13.734-2.051 14.742a5.2 5.2 0 01-3.442.843 4.566 4.566 0 01-4.307-4.907l.316-2.792a15.757 15.757 0 01.232-2.049l.251-2.219c.563-10.349-3.33-33.7-4.142-37.333l-.008-.044v-.01a12.97 12.97 0 01-.166-.854l-.241-1.235a1.438 1.438 0 011.1-1.673 1.376 1.376 0 011.736.924z"
          fill="#e53e51"
        />
        <path
          data-name="Path 30"
          d="M32.25 3.151c.163.5.324 1.038.477 1.515 1.362 4.178 4.6 12.948 7.343 22.532.971 3.394 1.6 6.785 2.264 10.386 1.934 10.459-.436 13.734-2.051 14.742a5.2 5.2 0 01-3.442.843 4.566 4.566 0 01-4.307-4.907l.316-2.792a15.757 15.757 0 01.232-2.049l.251-2.219c.563-10.349-3.33-33.7-4.142-37.333l-.008-.044v-.01a12.97 12.97 0 01-.166-.854l-.241-1.235a1.438 1.438 0 011.1-1.673 1.376 1.376 0 011.736.924z"
          fill="#e53e51"
        />
        <path
          data-name="Path 31"
          d="M45.929 12.889v-.022c-.157-.315.258-4.518-13.459-2.274a88.015 88.015 0 00-9.261 2.003c-2.047.568-3.57 1.011-5.489 1.6.207 1.416.43 3.017.7 5.022 7.3-1 14.56-1.818 18.683-1.429h.015c.302.049 9.505 1.584 8.811-4.9z"
          fill="#2a3a6f"
        />
        <g data-name="Group 8">
          <g
            data-name="Group 7"
            clipPath="url(#prefix__f)"
            transform="translate(31.475 17.686)"
            opacity={0.83}
          >
            <path
              data-name="Rectangle 5"
              transform="translate(-2.903 -1.585)"
              opacity={0.74}
              fill="url(#prefix__g)"
              d="M0 0h20.429v38.212H0z"
            />
          </g>
        </g>
        <g data-name="Group 10">
          <g
            data-name="Group 9"
            clipPath="url(#prefix__h)"
            transform="translate(28.737)"
          >
            <path
              data-name="Rectangle 6"
              transform="translate(-.592 -2.983)"
              fill="url(#prefix__i)"
              d="M0 0h8.167v15.277H0z"
            />
          </g>
        </g>
        <g data-name="Group 12">
          <g
            data-name="Group 11"
            clipPath="url(#prefix__j)"
            transform="translate(3.689 31.355)"
          >
            <path
              data-name="Rectangle 7"
              transform="rotate(-90 8.058 8.284)"
              opacity={0.64}
              fill="url(#prefix__a)"
              d="M0 0h20.429v32.62H0z"
            />
          </g>
        </g>
        <g data-name="Group 14">
          <g
            data-name="Group 13"
            clipPath="url(#prefix__k)"
            transform="translate(41.215 31.704)"
          >
            <path
              data-name="Rectangle 8"
              transform="rotate(-90 3.726 8.134)"
              opacity={0.64}
              fill="url(#prefix__l)"
              d="M0 0h13.164v21.021H0z"
            />
          </g>
        </g>
        <g data-name="Group 16">
          <g
            data-name="Group 15"
            clipPath="url(#prefix__m)"
            transform="translate(17.723 9.932)"
          >
            <path
              data-name="Rectangle 9"
              transform="rotate(-106.485 7.871 8.275)"
              opacity={0.64}
              fill="url(#prefix__n)"
              d="M0 0h19.164v30.601H0z"
            />
          </g>
        </g>
      </svg>

    </div>

  )
}

export default WTNSpinner
