import React, { useState } from "react";
import { WTNScaleProps } from "./WTNScale.types";
import WTNScaleCanvas from "./components/WTNScaleCanvas/WTNScaleCanvas";
import WTNInfoIcon from "./components/WTNScaleTooltip/WTNInfoIcon";
import styles from "./WTNScale.module.scss";
import useViewport from "../hooks/use-viewport";
import WTNLogo from "./components/WTNLogo/WTNSVGs";
import WTNSelect from "./components/WTNSelect/WTNSelect";

const WTNScale: React.FC<WTNScaleProps> = ({
  mode,
  wtnSingles,
  wtnDoubles,
  gameZoneUpper = 0,
  gameZoneLower = 0,
  width = 350,
  animDuration = 4500,
  mobileBreakPoint = 767.98,
  labelsDesktop = true,
  labelsMobile = true,
  border = false,
  infoIcon = true,
  infoIconSize = 25,
  infoModal = true,
  infoModalZIndex = 500,
  proPlayerSingles = false,
  proPlayerDoubles = false,
  confidenceSingles = 0,
  confidenceDoubles = 0,
  sourceIsPsqDoubles = false,
  sourceIsPsqSingles = false,
}) => {
  const singlesText = "Singles",
    doublesText = "Doubles",
    darkBlue = "#16234C",
    WTNlogoSizeDesktop = width / 4.5,
    btnSize = width / 25,
    infoIconRelativeSize = (infoIconSize + width) / 15;

  const [category, setCategory] = useState(singlesText);
  const { deviceWidth } = useViewport();

  const modeSinglesOrDoubles = mode === "singles" || mode === "doubles";

  const toggleCategory = () => {
    category === singlesText
      ? setCategory(doublesText)
      : setCategory(singlesText);
  };

  const desktopDynamicStyles = {
    width: `${1.02 * width}px`,
    height: `${1.03 * width}px`,
    border: border ? `1px solid ${darkBlue}` : "",
  };

  const mobileDynamicStyles = {
    border: border ? `1px solid ${darkBlue}` : "",
  };

  const desktopProps = {
    canvasWidth: width * 1.4,
    labels: labelsDesktop,
    animDuration,
    version: 2,
  };

  const desktopSinglesProps = {
    WTN: wtnSingles,
    gameZoneLower,
    gameZoneUpper,
    proPlayer: proPlayerSingles,
    confidence: confidenceSingles,
    sourceIsPsq: sourceIsPsqSingles,
    noWTNSingles: !wtnSingles,
    ...desktopProps,
  };

  const desktopDoublesProps = {
    WTN: wtnDoubles,
    gameZoneLower: 0,
    gameZoneUpper: 0,
    proPlayer: proPlayerDoubles,
    confidence: confidenceDoubles,
    sourceIsPsq: sourceIsPsqDoubles,
    noWTNDoubles: !wtnDoubles,
    ...desktopProps,
  };

  const mobileProps = {
    canvasWidth: 340,
    labels: labelsMobile,
    animDuration,
    version: 2,
  };

  const mobileSinglesProps = {
    WTN: wtnSingles,
    gameZoneLower,
    gameZoneUpper,
    proPlayer: proPlayerSingles,
    confidence: confidenceSingles,
    sourceIsPsq: sourceIsPsqSingles,
    noWTNSingles: !wtnSingles,
    ...mobileProps,
  };
  const mobileDoublesProps = {
    WTN: wtnDoubles,
    gameZoneLower: 0,
    gameZoneUpper: 0,
    proPlayer: proPlayerDoubles,
    confidence: confidenceDoubles,
    sourceIsPsq: sourceIsPsqDoubles,
    noWTNDoubles: !wtnDoubles,
    ...mobileProps,
  };

  // Desktop
  if (deviceWidth > mobileBreakPoint) {
    return (
      <div
        data-testid="WTNScale"
        className={styles.WTNScaleWrapper}
        style={desktopDynamicStyles}
      >
        <div className={styles.WTNScaleWrapperBtns}>
          {modeSinglesOrDoubles && (
            <h1
              style={{ fontSize: btnSize, cursor: "default" }}
              className={`${styles.WTNScaleBtn} ${styles.WTNScaleActiveBtn}`}
            >
              {mode}
            </h1>
          )}
          {!modeSinglesOrDoubles && (
            <>
              <button
                type="button"
                style={{ fontSize: btnSize }}
                className={`${styles.WTNScaleBtn} ${
                  category === singlesText ? styles.WTNScaleActiveBtn : ""
                }`}
                onClick={() =>
                  category === singlesText ? null : toggleCategory()
                }
              >
                {singlesText}
              </button>
              <span className={styles.WTNScaleDivider}></span>
              <button
                type="button"
                style={{ fontSize: btnSize }}
                className={`${styles.WTNScaleBtn} ${
                  category === singlesText ? "" : styles.WTNScaleActiveBtn
                }`}
                onClick={() =>
                  category === doublesText ? null : toggleCategory()
                }
              >
                {doublesText}
              </button>
            </>
          )}
          {infoIcon && (
            <>
              <WTNInfoIcon
                size={infoIconRelativeSize}
                infoModal={infoModal}
                infoModalZIndex={infoModalZIndex}
              />
            </>
          )}
        </div>

        <WTNLogo logoSize={WTNlogoSizeDesktop} />

        <div className={styles.WTNScaleWrapperContainer}>
          {!modeSinglesOrDoubles && (
            <>
              {category === singlesText && (
                <WTNScaleCanvas {...desktopSinglesProps} />
              )}
              {category === doublesText && (
                <WTNScaleCanvas {...desktopDoublesProps} />
              )}
            </>
          )}

          {modeSinglesOrDoubles && (
            <>
              {mode === "singles" && (
                <WTNScaleCanvas {...desktopSinglesProps} />
              )}
              {mode === "doubles" && (
                <WTNScaleCanvas {...desktopDoublesProps} />
              )}
            </>
          )}
        </div>
      </div>
    );
  }
  // Mobile
  else {
    return (
      <div
        data-testid="WTNScale"
        className={styles.WTNScaleWrapper}
        style={mobileDynamicStyles}
      >
        <div className={styles.WTNScaleSelectCase}>
          {modeSinglesOrDoubles && (
            <div className={styles.WTNScaleSelect}>
              <h2
                style={{
                  margin: "0",
                  fontFamily: "Poppins",
                  fontSize: "1rem",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  lineHeight: "0.7",
                  color: darkBlue,
                }}
              >
                {mode}
              </h2>
            </div>
          )}

          {!modeSinglesOrDoubles && (
            <WTNSelect
              values={[singlesText, doublesText]}
              setSelected={setCategory}
              selected={category}
            />
          )}

          {infoIcon && (
            <WTNInfoIcon
              size={40}
              infoModal={infoModal}
              infoModalZIndex={infoModalZIndex}
            />
          )}
        </div>
        <WTNLogo logoSize={70} />

        <div className={styles.WTNScaleWrapperContainer}>
          {modeSinglesOrDoubles && (
            <>
              {mode === "singles" && <WTNScaleCanvas {...mobileSinglesProps} />}

              {mode === "doubles" && <WTNScaleCanvas {...mobileDoublesProps} />}
            </>
          )}

          {!modeSinglesOrDoubles && (
            <>
              {category === singlesText && (
                <WTNScaleCanvas {...mobileSinglesProps} />
              )}

              {category === doublesText && (
                <WTNScaleCanvas {...mobileDoublesProps} />
              )}
            </>
          )}
        </div>
      </div>
    );
  }
};

export default WTNScale;
