import React from "react";
import useModal from "../../../hooks/use-modal";
import styles from "./WTNInfoIcon.module.scss";
import WTNModal from "../../components/WTNModal/Modal";

interface Props {
  size: number;
  infoModal?: boolean;
  infoModalZIndex: number;
}

const WTNScaleTooltip: React.FC<Props> = ({
  size,
  infoModal,
  infoModalZIndex,
}) => {
  const { isVisible, toggleModal } = useModal();

  if (!infoModal) {
    return (
      <a
        data-testid="infoIcon"
        href="https://www.worldtennisnumber.com/"
        target="_blank"
        className={styles.WTNInfoIcon}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={size}
          height={size}
          fill="none"
          viewBox="0 0 16 16"
        >
          <path
            stroke="#737680"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="1.2"
            d="M2.4 8.098C2.347 5.005 4.907 2.455 8 2.4c3.092-.054 5.545 2.41 5.599 5.502.053 3.091-2.507 5.643-5.6 5.696-3.092.054-5.545-2.41-5.599-5.501zM8 7.527v3.31"
          />
          <path
            fill="#737680"
            stroke="#737680"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth=".9"
            d="M7.764 5.636c0-.13.105-.236.236-.236.13 0 .236.106.236.236 0 .131-.105.237-.236.237-.13 0-.236-.106-.236-.237z"
          />
        </svg>
      </a>
    );
  } else {
    return (
      <>
        <span
          data-testid="infoIcon"
          onClick={toggleModal}
          className={styles.WTNInfoIcon}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            viewBox="0 0 16 16"
          >
            <path
              stroke="#737680"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              strokeWidth="1.2"
              d="M2.4 8.098C2.347 5.005 4.907 2.455 8 2.4c3.092-.054 5.545 2.41 5.599 5.502.053 3.091-2.507 5.643-5.6 5.696-3.092.054-5.545-2.41-5.599-5.501zM8 7.527v3.31"
            />
            <path
              fill="#737680"
              stroke="#737680"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              strokeWidth=".9"
              d="M7.764 5.636c0-.13.105-.236.236-.236.13 0 .236.106.236.236 0 .131-.105.237-.236.237-.13 0-.236-.106-.236-.237z"
            />
          </svg>
        </span>
        <WTNModal
          isVisible={isVisible}
          hideModal={toggleModal}
          zIndex={infoModalZIndex}
        />
      </>
    );
  }
};

export default WTNScaleTooltip;
